import React, { useState } from "react";
import "./Schedulepage.css";
import Bookingnavbar from "../components/Bookingnavbar";
import greendot from "../assets/dotgreen.svg";
import profileimg from "../assets/workerimg.png";
import info from "../assets/info.svg";
import timeicon from "../assets/ttime.svg";
import calendericon from "../assets/tcalendar.svg";
import locationicon from "../assets/tlocation.svg";
import moneyicon from "../assets/money.svg";
import taskicon from "../assets/taskicon.svg";
import searchicon from "../assets/searchicon.svg";
import cancelicon from "../assets/cancelicon.svg";
import imgicon from "../assets/imageicon.svg";
import sendicon from "../assets/sendicon.svg";
import { useNavigate } from "react-router-dom";
import likeicon from "../assets/likeicon.svg";
import dislikeicon from "../assets/dislikeicon.svg";

function Completedpage() {
  const [selectedNav, setSelectedNav] = useState("task info");
  const [showRate, setShowRate] = useState("");
  let navigate = useNavigate();
  return (
    <>
      <div className='mytcnt mytcnts'>
        <Bookingnavbar />

        <div className='mytaskcnt mytaskcnts'>
          <div className='scheduletop'>
            <h1 className='mytaskcnttitle'>Pipe Repair</h1>
            {selectedNav === "task info" && (
              <div className='schedulestatusgreen'>
                <img src={greendot} alt='' className='schedulestatusdot' />
                <label htmlFor='' className='schedulestatustextgreen'>
                  Completed
                </label>
              </div>
            )}
          </div>

          <p className='completetsktitle'>Task info</p>

          <div className='scheduledtaskcnt'>
            <div className='tskdtl-items'>
              <div className='tskdtll-item'>
                <div className='tskdtl-ileft'>
                  <img src={calendericon} alt='' className='tskdticon' />
                  <label htmlFor=''>Date</label>
                </div>
                <div className='tskdtl-iright'>
                  <label htmlFor=''>Sun, Nov 25, 2024</label>
                </div>
              </div>
              <div className='scheduledivider'></div>
              <div className='tskdtll-item'>
                <div className='tskdtl-ileft'>
                  <img src={locationicon} alt='' className='tskdticon' />
                  <label htmlFor=''>Location</label>
                </div>
                <div className='tskdtl-iright'>
                  <label htmlFor=''>VI Lagos</label>
                </div>
              </div>
              <div className='scheduledivider'></div>
              <div className='tskdtll-item'>
                <div className='tskdtl-ileft'>
                  <img src={timeicon} alt='' className='tskdticon' />
                  <label htmlFor=''>Technician arrival time</label>
                </div>
                <div className='tskdtl-iright'>
                  <label htmlFor=''>9:30 AM</label>
                </div>
              </div>
              <div className='scheduledivider'></div>
              <div className='tskdtll-item'>
                <div className='tskdtl-ileft'>
                  <img src={timeicon} alt='' className='tskdticon' />
                  <label htmlFor=''>Task completion time</label>
                </div>
                <div className='tskdtl-iright'>
                  <label htmlFor=''>11:30 AM</label>
                </div>
              </div>
              <div className='scheduledivider'></div>
              <div className='tskdtll-item'>
                <div className='tskdtl-ileft'>
                  <img src={timeicon} alt='' className='tskdticon' />
                  <label htmlFor=''>Time taken</label>
                </div>
                <div className='tskdtl-iright'>
                  <label htmlFor=''>1hr</label>
                </div>
              </div>
              <div className='scheduledivider'></div>
              <div className='tskdtll-item tsrish'>
                <div className='tskdtl-ileft'>
                  <img src={moneyicon} alt='' className='tskdticon' />
                  <label htmlFor=''>Price breakdown</label>
                </div>
                <div className='tskdtl-iright'>
                  <div className='tskdtl-item'>
                    <div className='tsdlir-item'>
                      <label htmlFor='' className='tsdlirtitle'>
                        Hourly rate
                      </label>
                      <label htmlFor='' className='tsdlirpar'>
                        $26.93/hr
                      </label>
                    </div>
                    <div className='tsdlir-item'>
                      <label htmlFor='' className='tsdlirtitle'>
                        Trust/support fee
                      </label>
                      <label htmlFor='' className='tsdlirpar'>
                        $12.93/hr
                      </label>
                    </div>
                    <div className='tsdlir-item'>
                      <label htmlFor='' className='tsdlirtitle'>
                        Total fee
                      </label>
                      <label htmlFor='' className='tsdlirpar'>
                        $12.93/hr
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='scheduledivider'></div>
              <div className='tskdtll-item tsrish'>
                <div className='tskdtl-ileft'>
                  <img src={taskicon} alt='' className='tskdticon' />
                  <label htmlFor=''>Task details/notes</label>
                </div>
                <div className='tskdtl-iright'>
                  <div className='tskdtlrightcnt'>
                    <p className='tskdtl-par'>
                      Hi, I need help fixing a leaking faucet in my kitchen.
                      It’s been dripping non-stop, and I’d like to get it
                      repaired as soon as possible. Please let me know if you're
                      available and what the next steps are. Thanks! It’s been
                      dripping non-stop, and I’d like to get it repaired as soon
                      as possible. Please let me know if you're available and
                      what the next steps are. Thanks!
                    </p>
                  </div>
                </div>
              </div>
              <div className='scheduledivider'></div>
              <div className='completedivid'>
                <div className='scheduleprofile'>
                  <p className='scheduleprofiletitle'>Technician/tasker</p>
                  <div className='sprofile'>
                    <div className='sprofileleft'>
                      <img src={profileimg} alt='' />
                    </div>
                    <div className='sprofileright'>
                      <h2 className='spname'>Ben Affleck</h2>
                      <div
                        className={
                          4.5 <= 3.5 ? "wlitopryellow" : "wlitoprgreen"
                        }
                      >
                        <svg
                          width='12'
                          height='12'
                          viewBox='0 0 15 15'
                          fill='#fff'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7.22303 0.665992C7.32551 0.419604 7.67454 0.419604 7.77702 0.665992L9.41343 4.60039C9.45663 4.70426 9.55432 4.77523 9.66645 4.78422L13.914 5.12475C14.18 5.14607 14.2878 5.47802 14.0852 5.65162L10.849 8.42374C10.7636 8.49692 10.7263 8.61176 10.7524 8.72118L11.7411 12.866C11.803 13.1256 11.5206 13.3308 11.2929 13.1917L7.6564 10.9705C7.5604 10.9119 7.43965 10.9119 7.34365 10.9705L3.70718 13.1917C3.47945 13.3308 3.19708 13.1256 3.25899 12.866L4.24769 8.72118C4.2738 8.61176 4.23648 8.49692 4.15105 8.42374L0.914889 5.65162C0.712228 5.47802 0.820086 5.14607 1.08608 5.12475L5.3336 4.78422C5.44573 4.77523 5.54342 4.70426 5.58662 4.60039L7.22303 0.665992Z'
                            fill='#fff'
                          ></path>
                        </svg>
                        <label htmlFor=''>4.5</label>
                      </div>
                      <div className='sptypo'>
                        <p className='greatvalue'>GREAT VALUE</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className='cmpratebtn'
                  onClick={() => setShowRate("rate")}
                >
                  Rate technician
                </button>
              </div>


                        
              <div className='scheduledivider'></div>
            </div>
          </div>
        </div>
      </div>
      {showRate === "rate" && (
        <div className='overlayy'>
          <div
            className='successmodal ratemodal'
            onClick={(e) => e.stopPropagation()}
          >
            <h1>Help us out!</h1>
            <p className='btstslefttitle'>Rate Ben Affleck’s service</p>
            <p className='btstsleftpara'>
              Your feedback helps us improve and ensures quality service for
              everyone!
            </p>
            <div className='ratingbtns'>
              <button
                className='rbtns'
                onClick={() => setShowRate("rating words")}
              >
                <img src={likeicon} alt='' />
              </button>
              <button
                className='rbtns'
                onClick={() => setShowRate("rating words")}
              >
                <img src={dislikeicon} alt='' />
              </button>
            </div>
          </div>
        </div>
      )}
      {showRate === "rating words" && (
        <div className='overlayy'>
          <div
            className='successmodal rwmodal'
            onClick={(e) => e.stopPropagation()}
          >
            <p className='btstsleftpara rwpar'>
              Please share extra information about this services
            </p>
            <div class='textarea-container ratingwordsarea'>
              <textarea placeholder='The service is good'></textarea>
            </div>
            <button
              className='successmodalbtn'
              onClick={() => {
                setShowRate("");
                navigate("/completed_task");
              }}
            >
              Continue
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Completedpage;
